import React from "react";
import Typography from "@material-ui/core/Typography";
import styles from "./Contents.module.scss";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

export default () => {
  return (
    <div className={styles.root}>
      <Typography variant="h1">コンテンツ</Typography>

      <Typography className={styles.lead}>
        箕澤屋関連コンテンツです。
        <br />
      </Typography>

      <Typography variant="h2" className={styles.secTitle}>
        屋根裏トーク
        <a
          href="https://www.youtube.com/playlist?list=PLpFjbwWOasbHxaFaGJ61ytnLcLTfAqacp"
          rel="noopener noreferrer"
          target="_blank"
          className={styles.secTitleLink}
        >
          YouTubeプレイリスト{" "}
          <ArrowForwardIosIcon className={styles.secTitleArrow} />
        </a>
      </Typography>

      <div className={`${styles.video}`}>
        <div className={styles.videoItem}>
          <div className={styles.videoInner}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/t0v7iu9sn1Q"
              title="Slack〜農園、花粉症、ワクチン、新しいマイク"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullscreen
            ></iframe>
          </div>

          <Typography variant="h3" className={styles.videoTitle}>
            #4 Slack〜農園、花粉症、ワクチン、新しいマイク
          </Typography>
        </div>

        <div className={styles.videoItem}>
          <div className={styles.videoInner}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/z4rLMz571GY"
              title="究極のバランス派〜いいバランスをとるのは難しい"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>

          <Typography variant="h3" className={styles.videoTitle}>
            #3 究極のバランス派〜いいバランスをとるのは難しい
          </Typography>
        </div>

        <div className={styles.videoItem}>
          <div className={styles.videoInner}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/JkwEV8ycKXE"
              title="リモート時代の時間の使い方"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>

          <Typography variant="h3" className={styles.videoTitle}>
            #2 リモート時代の時間の使い方
          </Typography>
        </div>

        <div className={styles.videoItem}>
          <div className={styles.videoInner}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/ptLroD8e1MY"
              title="人前で話をすると、なぜ緊張するのでしょうか"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>

          <Typography variant="h3" className={styles.videoTitle}>
            #1 人前で話をすると、なぜ緊張するのでしょうか
          </Typography>
        </div>
      </div>

      <Typography variant="h2" className={styles.secTitle}>
        QULA限定 作品集
      </Typography>

      <div className={`${styles.video} ${styles.pickup}`}>
        <div className={styles.videoItem}>
          <div className={styles.videoInner}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/27hdGOG0NuQ"
              title="パロディ短編「箕澤屋のロッキー」"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>

          <Typography variant="h3" className={styles.videoTitle}>
            パロディ短編「箕澤屋のロッキー」
          </Typography>
        </div>

        <div className={styles.videoItem}>
          <div className={styles.videoInner}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/PvZR8hGkcrs"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullscreen
              title="カルロス先生の換気扇お掃除方法"
            ></iframe>
          </div>

          <Typography variant="h3" className={styles.videoTitle}>
            カルロス先生の換気扇お掃除方法
          </Typography>
        </div>
      </div>

      {/* <Typography variant="h2" className={styles.secTitle}>
        コバダイシリーズ
      </Typography>

      <div className={`${styles.video}`}>
        <div className={styles.videoItem}>
          <div className={styles.videoInner}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/131qXQdGH58"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="2021.2月号"
            ></iframe>
          </div>

          <Typography variant="h3" className={styles.videoTitle}>
            2021.2月号
          </Typography>
        </div>

        <div className={styles.videoItem}>
          <div className={styles.videoInner}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/BBzMpMx522M"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="2021.2.2 節分 4K"
            ></iframe>
          </div>

          <Typography variant="h3" className={styles.videoTitle}>
            2021.2.2節分 4K
          </Typography>
        </div>

        <div className={styles.videoItem}>
          <div className={styles.videoInner}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/oO_f-FjLaKA"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="2021 新年号"
            ></iframe>
          </div>

          <Typography variant="h3" className={styles.videoTitle}>
            2021 新年号
          </Typography>
        </div>

        <div className={styles.videoItem}>
          <div className={styles.videoInner}>
            <iframe
              src="https://www.youtube.com/embed/ETOQyQuGiwU"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="令和三年元旦の箕澤屋"
            ></iframe>
          </div>
          <Typography variant="h3" className={styles.videoTitle}>
            令和三年元旦の箕澤屋
          </Typography>
        </div>
      </div> */}

      <Typography variant="h3" gutterBottom className={styles.secTitle}>
        大掃除シリーズ
        <a
          href="https://www.youtube.com/playlist?list=PLpFjbwWOasbHqzhcCJvOtpLmjr2DzPfwa"
          rel="noopener noreferrer"
          target="_blank"
          className={styles.secTitleLink}
        >
          YouTubeプレイリスト{" "}
          <ArrowForwardIosIcon className={styles.secTitleArrow} />
        </a>
      </Typography>

      <div className={`${styles.video}`}>
        <div className={styles.videoItem}>
          <div className={styles.videoInner}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/u5GdeeAC8Dg"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="古民家の土間への砂利敷き"
            ></iframe>
          </div>

          <Typography variant="h3" className={styles.videoTitle}>
            古民家の土間への砂利敷き
          </Typography>
        </div>

        <div className={styles.videoItem}>
          <div className={styles.videoInner}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/7PNlPbdyIlE"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="古民家の大掃除④ 長年積もった梁のほこり落とし"
            ></iframe>
          </div>

          <Typography variant="h3" className={styles.videoTitle}>
            古民家の大掃除④ 長年積もった梁のほこり落とし
          </Typography>
        </div>

        <div className={styles.videoItem}>
          <div className={styles.videoInner}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/pz0sj_fdtJs"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="古民家の大掃除③ 土間＆大広間のモノを水車小屋へ移動"
            ></iframe>
          </div>

          <Typography variant="h3" className={styles.videoTitle}>
            古民家の大掃除③ 土間＆大広間のモノを水車小屋へ移動
          </Typography>
        </div>

        <div className={styles.videoItem}>
          <div className={styles.videoInner}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/QADt6KTt_2o"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="古民家の大掃除② 土蔵のモノを整理"
            ></iframe>
          </div>
          <Typography variant="h3" className={styles.videoTitle}>
            古民家の大掃除② 土蔵のモノを整理
          </Typography>
        </div>
      </div>
    </div>
  );
};
