import React from "react";
import Layout from "../../components/member/Layout";
import Contents from "../../components/member/Contents/Contents";

export default () => {
  const pageSlug = "contents";
  const pageTitle = "チャンネル";
  return (
    <Layout pageSlug={pageSlug} pageTitle={pageTitle}>
      <Contents />
    </Layout>
  );
};
